import React from 'react';
import './Footer.css';

function Footer() {
  // Replace with your actual Google Form URL
  const googleFormUrl = 'https://forms.gle/U6Ef2sjfrJViQdHy8';

  const handleFeedback = () => {
    window.open(googleFormUrl, '_blank');
  };

  return (
    <footer>
      <div>
        <button onClick={handleFeedback} className="feedback-button">
          Send Feedback
        </button>
      </div>
      <div className='privacy-policy'>
        <span className="foot">Elite Corporate Shots &copy; 2024</span>
        <br/>
        <a href='/privacy-policy'>privacy policies</a>
      </div>
    </footer>
  );
}

export default Footer;
