import Footer from "./Footer";
import Header from "./Header";
function PrivacyPolicy() {
    return (
        <>
        <Header/>

        <span>
Our Privacy Policy

Thank you for visiting https://elitecorporateshots.pics/ ("the Site")! The Site is strongly committed to protecting your privacy. The purpose of this privacy policy is to inform users of the Site as to what information we collect and how and why we use such information. The Site is a website published and maintained by Elite Corporate Shots. The terms “we”, “our” and “us” refer to Elite Corporate Shots and our affiliated companies.



1. Disclosure Regarding Collection and Use of Personal Data.



Through the Site, users may choose to enter personal data and information about themselves. If you decide to share personal data with us, we will ask for your consent to use that data as described in this privacy policy. This policy explains our online information collection and use practices and the choices you can make about the way we use and share such information. Please take the time to read and understand this policy so that you can understand how we use your personal data. Please note that our online data collection and use practices may differ from our practices regarding data obtained offline.



REMEMBER: IF YOU MAKE USE OF THE SITE, REGISTER WITH THE SITE, REQUEST INFORMATION THAT WE MAY OFFER OR SUBMIT DATA IN ANY WAY TO OR THROUGH THE SITE YOU AGREE TO THESE TERMS. AS WE UPDATE AND EXPAND OUR SERVICES, THIS POLICY MIGHT CHANGE, SO PLEASE REVIEW IT FROM TIME TO TIME.



2. The Personal Data We Collect.



We may collect personal data and information about you through a variety of methods, such as your style requests, your face picture, etc. and other information available on Platforms or other documents that you submit to us, transactions in which you may engage with us or our affiliated companies, or through visits that you may make to the Site. This policy applies to all personal data that we collect and maintain about our users, including any contact information (e.g., name, address, phone number, email address), financial information (e.g., account, credit card details, debit card details, etc.), identifying information (e.g., Aadhar number, date of birth), demographic information (gender, socio-economic profile, category, caste, race, religion, minority status, etc.), education details such as education Interest, current education level, year of passing, score, rank in exams, enrolment number, and/or other preferences/interests (including but not limited to preferred location of study, choice of programs/courses), that we may obtain in connection with providing services. You may view or change your information at any time by contacting us or by signing into your account and editing your profile. In addition, you may withdraw your consent to our use of your personal data. If you do so, you may also request that we remove that data from our records and databases.



3. Other Collected Information.



We also may collect certain non-personal data (i.e. generic information that does not identify you personally) when you visit any of our web pages such as the type of browser you are using (e.g., Chrome, Internet Edge), the type of operating system you are using, (e.g., Windows 10 or macOS), the IP address you are using to connect to the Site and the domain name of your Internet service provider (e.g., Spectrum or Comcast).



4. How We Use Your Personal Data.



We use your personal data to provide you with products or services that you have requested, to respond to questions or communications and to get in touch with you when necessary. For example, we may use your personal data to communicate with you or to send you information that you have requested. Except as otherwise set forth on the Site, in addition, we may send to you an email offering products or services which we think you may be interested in or to ask you to participate in a survey. We do not consider any companies that are within our "family" of companies to be "third parties" for purposes of this policy. Thus, we may also share personal data provided online with companies affiliated with or owned directly or indirectly by Elite Corporate Shots. Any of these companies may use and further share this data in the same ways we do. However, if we pass on personal data to a family company, we require that it protect such data in a manner that is consistent with this policy and your expressed preferences. For example, a family company may send to you an email regarding special offers and announcements about products or services that it thinks might be of interest to you. A family company may also contact you offline. If you want to opt-out from our (or a family company) using the personal data you provided in these ways, please contact us.



Moreover, we require this information to understand your needs and to provide you better service; in particular for all or some of the following reasons: 1) To customise experience on our platforms based on your profile and interests, 2) To recommend the ‘best fit college’ with the information provided by you, 3)To process your application(s) to our partner Colleges and Universities which require the information to assess your suitability for admission, 4) To send you updates about the exams, schools / colleges / universities / service providers, courses, programs, events and other information that you may be interested in, 5) To tailor the communications that we send you about our products and services based on your profile, 6) To allow you to participate in surveys, reviews, promotional offers or interactive features of our products/services when you choose to do so, 7) To respond appropriately to any questions or comments that you submitted to use on our platforms or otherwise., 8) For Internal record keeping, 9) To enhance products, functionalities and services offered by us



We may periodically send promotional emails/SMS about new products, special offers or other information which we think you may find interesting using the email address which you have provided



From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone or mail. We may use this information to customize the platform according to demographic interests



Your personal data may be shared with selected educational institutions to communicate their marketing campaigns for their courses / programs which may be beneficial to you.



You can at any time ask us to stop sending you marketing information or withdraw the permission to contact you by mailing us at barsopia.vivek@gmail.com



5. Updating or Removing Your Personal Data.



Please contact us at any time at barsopia.vivek@gmail.com to update, remove or change your personal data.



6. How We Share Your Personal Data.



We will not share with third parties the personal data you provide except (a) if it is for the purpose(s) you provided it; (b) with your consent; (c) as may be required by law or as we think necessary to protect our company or others from injury (e.g. in response to a court



order or subpoena, in response to a law enforcement agency request or when we believe that someone is causing (or about to cause) injury to or interference with the rights or property of another); (d) with other persons, service providers or companies with whom we contract to carry out internal site operations or our business activities (for example, sending out a product or a promotional item that you have requested on the Site); and/or (e) notifying you when we make material changes to one of our subscriber agreements or this policy. Examples of us sharing your personal data for the purposes submitted are if you participate in a third-party survey or request information from a third-party, we may provide the information provided to such third party so that they can send to you directly the information you requested. We will seek to obtain your consent at the place where the information is collected either by an "opt-in" or "opt-out" method, or by other means (such as sending to you an email). As stated above, we do not consider any companies that are within our "family" of companies to be "third parties" for purposes of this policy and may also share personal data provided online with companies affiliated with or owned directly or indirectly by Elite Corporate Shots. Any of these companies may use and further share this information in the same ways we do. However, if we pass on personal data to a family company, we require that it protect such information in a manner that is consistent with this policy. If you want us to stop sharing your personal data among Carnegie-owned companies, please contact us.



Additionally, we may share your personal data with schools, colleges, and universities so they can provide you with information about their educational offerings.



Additionally, with your consent, we may share the personal data you provide online with companies not affiliated with us (i.e. third-party companies) who are interested in marketing their products or services to you or in asking you questions, both online and offline. Companies with whom we might share your personal data may include consumer products, telecom, financial, military, market research, entertainment, and educational services companies and their third-party service providers. If you want us to stop sharing your personal data with these companies, please contact us. If you consent to receiving communications from non-affiliated companies, you may need to communicate with them directly if you later decide that you no longer wish to receive their communications or if you wish to change any data you provided online. In this instance, any changes or amendments to your personal data must be given directly to each company who communicates with you as these changes, amendments and/or deletions will not be exchanged between us and non-affiliated companies.



If we do share personal data with a non-affiliated company, to the extent practical, we require that they protect such information in a manner that is consistent with this policy. We cannot, however, guarantee how these non-affiliated companies will use your personal data.



7. Personal Data Gathered Under Previous Versions of This Privacy Policy.



Personal data collected via the Site will generally be governed by the version of the Privacy Policy in effect at the time the information was gathered. We will not retroactively make material changes to the way we use personally identifiable information collected under previous versions of this Privacy Policy without obtaining your consent.



8. Use and Disclosure of Anonymous Information.



We sometimes use the non-personal data that we collect to improve the design and content of the Site and to enable us to personalize your Internet experience. We also may use this information in the aggregate to analyze how the Site is used, as well as to offer



you products, programs or services. Sometimes we share aggregate information with others, including affiliated companies and non-affiliated companies.



9. Receipt of Communications; Withdrawal of Consent.



If you decide not to receive marketing or promotional emails from us that may relate, for example, to new services or offerings, you may withdraw your consent to receive these communications by contacting us via email.



Please note that we may require up to sixty (60) days to process your request.



Following your opt-out, please be aware that we, or our affiliates, may continue to contact you via email or postal mail for administrative purposes, including, but not limited to, follow-up messages regarding the administration of your account, any services, features or functions you remain affirmatively enrolled in or registered to use, any promotions or sweepstakes you have participated in or have entered, goods, services or products you have ordered or other transactions you have undertaken on the Site.



10. Collection of Information By Others.



The Site may contain links to other websites, including links that are placed there by us, such as third-party advertisements or text hyperlinks. Please be aware that we are not responsible for the privacy practices of any other website. We encourage all users of the Site to be aware of when they leave the Site, and to read the privacy policies of each and every website that collects personal data. This privacy policy applies solely to information collected by the Site, through the Site. We may offer additional email services or promotions that are sponsored by or co-sponsored with non-affiliated companies. Based on our agreement with them, these third parties may obtain personal data that site visitors voluntarily submit to participate in the contest, sweepstakes, or promotion. We have no control over the use of this information by these companies.



11. Cookies.



To enhance your online experience with us, many of our web pages use "cookies." Cookies are text files placed on your hard disk by our web server to store your preferences. Cookies, by themselves, do not tell us your email address or other personal data unless you choose to provide this information to us. However, once you choose to provide the personal data, this information may be linked to the data stored in the cookie. Cookies cannot be used to run programs or deliver viruses to your computer.



We use cookies as a convenience feature to save you time, to understand how the Site is used, and to improve the content and offerings on the Site. For example, we may use cookies to personalize your experience at our web pages or recall your specific information on subsequent visits (e.g. to recognize you by name when you return to the Site). We also may use cookies to offer you products, programs or services. You can opt to have your browser reject cookies. If you do that, you may need to re-register with us every time you visit the Site in order to participate in certain functions of the Site. You should also know that companies that advertise on the Site (and their agents) might use their own cookies. This policy does not cover how these companies use their cookies.



12. Clickstream Data.



As you use the Internet, a trail of electronic information is left at each web site you visit. This information, which is sometimes referred to as "Clickstream Data," can be collected and stored by a web site's server. Clickstream Data can tell us the type of computer and browsing software you use and the address of the web site from which you linked to the



Site. We may use Clickstream Data as a form of non-personal data to anonymously determine how much time visitors spend on each page of the Site, how visitors navigate throughout the Site and how we may tailor our web pages to better meet the needs of visitors. This information will only be used to improve the Site. Any collection or use of Clickstream Data will be anonymous and aggregate.



13. Supplementing Information.



From time to time we may supplement information you give us with information from other sources, such as information validating your address. Thus, information we obtain through the Site may be intermingled with and used in conjunction with information obtained through sources other than the Site, including both offline and online sources.



14. Discussions and Community Tools.



We may, from time to time, make review lists, chat rooms, forums, message boards, blogs, newsgroups and other community tools available to you. Please remember that any information that is disclosed in these areas becomes public information for other users to view and for us to use. For example, from time to time, we may use the content you write (including your name or screen name if you post it) for promotional purposes, in email newsletters or elsewhere, and by using the Site and/or these chat rooms, forums, message boards, news groups, and other community tools, you agree that we may do so. You should exercise caution when deciding to disclose your personal data in these areas, since anyone - not just us - may access your publicly posted information.



15. Our Commitment to Security.



We believe we have in place the appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access, maintain data security and correctly use the information we collect online. Whenever personal data is passed on to non-affiliated companies, to the extent practical, we require that they also have in place the appropriate safeguards. We cannot, however, guarantee that they do so.



16. How You Can Access or Correct Your Personal data.



You can access or correct your personal data by signing into your account and editing your profile. For instructions on how you can access the personal data that we have collected about you online, or how to correct any errors in such information, please contact us.



To protect your privacy and security, we will take reasonable steps to help verify your identity before granting access or making corrections.



17. Your Obligations



As a User, you have certain obligations towards other Users. Some of these obligations are imposed by applicable laws and regulations, and others have become commonplace to support a user-friendly interface and environment. Your obligations include but are not limited to:



You must, at all times, abide by the terms and conditions of the then-current Privacy Policy and Terms of use.



You must respect all intellectual property rights that may belong to third parties



You must not download or otherwise disseminate any information that may be deemed to be injurious, violent, offensive, racist or xenophobic, or which may otherwise violate the purpose and spirit of PPPL and its community of Users.



You must not provide us and/or other Users information that you believe might be injurious or detrimental to your person or to your professional or social status.



You must keep your password confidential and not share it with others.



Elite Corporate Shots operates channels, pages and accounts on some social media sites to inform, assist and engage with you. Elite Corporate Shots monitors and records comments and posts made on these channels to improve its products and services. Please note, you must not communicate with Elite Corporate Shots through such social media sites by divulging the following information:



• Sensitive personal data including information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation



Other sensitive personal data such as criminal convictions and offences and national identification number



Excessive, inappropriate, offensive or insulting information directed towards individuals/ entities



Any violation of these guidelines may lead to the restriction, suspension or termination of your account at our sole discretion.



For more information, refer to our Terms of Use 18. Cancellation and Refund Policy



Amount paid towards products purchased will neither be refunded nor adjusted under any circumstance.



However, if you have purchased products / services under ‘money back guarantee’ facility, the amount would be returned in case of not meeting the minimum guarantee promised, provided that you have fulfilled the terms and conditions pertaining to the offer provided by us.



Our customer service team would be available to address any concerns related to the product and services rendered by us. For further information, please write to us

at barsopia.vivek@gmail.com



19. Your Consent



By registering with us, you are expressly consenting to our collection, processing, storing, disclosing and handling of your information as set forth in this Policy now and as amended by us from time to time. Processing your information in any way, includes collecting, storing, deleting, using, combining, sharing, transferring and disclosing information. We believe that, every user of our Platforms must be in a position to provide an informed consent prior to providing any Information required for the use of any of the Platforms. Even if you reside outside India, your information will be transferred, processed and stored in accordance with the applicable data protection laws of India.



20. Authorization



In purview of the Telecom Regulatory Authority of India (TRAI) guidelines, you hereby authorise us and our affiliates/partners or otherwise who are accessing your Information by virtue of their association with the company to communicate with you through



telephone/mobile, Email, SMS, WhatsApp or other modes of communication even if Your number/numbers(s) is/are registered in the National Do Not Call Registry (NDNC) or www.nccptrai.gov.in



You are providing your express consent to receive information, including telemarketing, about products and services from us and you hereby agree and consent to our contacting you using the information you have provided to us. We may contact you by e-mail, phone, including automated dialling equipment, SMS, WhatsApp and other kinds of text message, social networks or any other means of communication.



21. User communications



When you send an email or other communication to the company, it may retain those communications in order to process your inquiries, respond to your requests and improve our Services.



22. Security Measures



We take utmost care to secure your Information from unauthorised access, usage or disclosure or alteration. We take appropriate steps and security measures to safeguard your Information and make sure that your Information is secured as mandated by law. For this purpose, we adopt reasonable security practices and procedures, in line with the industry standards, to include, technical, operational, managerial and physical security controls in order to protect your personal information from unauthorized access, or disclosure while it is under our control.



While we protect your personal information as per industry standards, you acknowledge that the internet or computer networks are not fully secure and we cannot provide any absolute assurance regarding the security of your personal information. Therefore, you shall not hold us responsible for any loss of your information, or any other data which you have shared.



23. Special Note for Parents.



We will not knowingly accept personal data from anyone under 13 years old in violation of applicable laws. If you believe that your child under 13 has gained access to the Site without your permission, please contact us. We have taken commercially reasonable steps to restrict use of the Site to those who are at least 13 years old. We do not market products or services for purchase by children and all products or services we market are for purchase by individuals over 16 years old only, or with adult permission.



24. Transfer of Business Assets.



The personal data you provide is considered a company asset and may be included among transferred assets if the Site, we, or parent(s) or any of our subsidiaries or affiliates or any of our assets are ever acquired by a third party.



25. Changes to this Policy.



Because our business needs may change over time, we reserve the right, at our discretion, to change, modify, add, or remove portions from this policy at any time. If at any time in the future we plan to use your personal data in a way that differs from this policy, we will revise this policy as appropriate. This policy is effective as of May 25, 2018.



26. How to Contact Us.



If you have any questions or concerns about this privacy policy or believe that we are not properly using or storing your personal data you may contact us or write to the following address:



Email: barsopia.vivek@gmail.com

</span>
<Footer/>

</>
);
}

export default PrivacyPolicy;
