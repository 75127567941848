import React, { useState, useEffect } from 'react';
import { signInWithGoogle, signOut } from './GoogleLogin';
import { auth } from './firebase';
import { Link } from 'react-router-dom'; // Import the Link component
import './Header.css';

function Header() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Add an observer to check the authentication status
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    // Cleanup the observer on component unmount
    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      // Handle errors if needed
      console.error(error.message);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
    } catch (error) {
      // Handle errors if needed
      console.error(error.message);
    }
  };

  return (
    <header className="bg-white shadow header2"  >
      <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center" style={{ justifyContent: 'space-between' }}>
        <Link to="/">
          <h1 className="text-3xl font-bold text-gray-900">Elite Corporate Shots</h1>
        </Link>        {user ? (
          <button
            onClick={handleSignOut}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          >
            Log Out
          </button>
        ) : (
          <button
            onClick={handleSignIn}
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded"
          >
            Log In
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
