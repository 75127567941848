//GeneratePhotosPage.js
import React, { useState,useEffect } from 'react';
import Header from './Header';
import GeneratePhotosPageUpload from './GeneratePhotosPageUpload';
import GeneratePhotosPageAPIcall from './GeneratePhotosPageAPIcall';
import PopulateGeneratedImages from './PopulateGeneratedImages';
import Footer from './Footer';
import "./GeneratePhotosPage.css";
import ImageGenerationProvider from './ImageGenerationContext'; // Import the provider

function GeneratePhotosPage() {
  const [downloadURL, setDownloadURL] = useState(null);
  const [poseURL, setPoseURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // Add this line

  const handleUploadComplete = (url) => {
    setDownloadURL(url);
  };

  const handleUploadStatusChange = (uploading) => {
    setIsUploading(uploading);
  };


  const handleImageSelect = (url) => {
    setPoseURL(url);
  };
  
  return (
    <ImageGenerationProvider> {/* Wrap components with the context provider */}
    <div className="bg-gray-100">
      <Header />
      <div className="container">
        <div className="">
        {/* <h1 className="">We are having Troubles now, please use our service later</h1> */}

          <h1 className="">Generate A Professional Headshot In Just 60 Seconds</h1>
         
          {/* Section 1: Choose your style */}
          <div className="">
            <h2 className="text-xl font-semibold mb-4">1. Select Your Desired Outfit</h2>
            {/* <StyleButtons /> */}
            {/* <StyleCategories /> */}
            {/* Placeholder images */}
            <PlaceholderImages onImageSelect={handleImageSelect} poseURL={poseURL} /> 
          </div>

          {/* Section 2: Upload a picture of your face */}
          <div className="">
            {/* <h2 className="text-xl font-semibold mb-4">2. Upload a picture of your face.</h2> */}
            {/* <UploadButtonAndPreview /> */}
            <GeneratePhotosPageUpload onUploadComplete={handleUploadComplete}  onUploadStatusChange={handleUploadStatusChange}  />
          </div>

          {/* Section 4: Options and Generate button */}
          <GeneratePhotosPageAPIcall downloadURL={downloadURL} poseURL={poseURL}   isUploading={isUploading} />
          <PopulateGeneratedImages />

        </div>
      </div>

    </div>
    <Footer />

    </ImageGenerationProvider>

  );
}

// Custom hook to check for mobile screen
function useIsMobile() {
  // Initialize state with the current window width
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set mobile state based on window width
      setIsMobile(window.innerWidth < 500);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isMobile;
}


function PlaceholderImages({ onImageSelect, poseURL }) {
  // Thesae are Pose images
  const imageSources = [
    '/poses/replicate-prediction-jui2jxjbz55x4l2q5mjxdnt4oa.webp',
    '/poses/replicate-prediction-agkqntrbxyp6sgirmsrt36gnle.webp',
    '/poses/replicate-prediction-cx6q63jbvaxkrbhh3l257fxxxm.webp',
    '/poses/replicate-prediction-mrevigjbllj5fzzfye3jbgf4v4.webp',

    '/poses/replicate-prediction-zwo7nijbqqquja5rpfkcbcapbq.webp',
    '/poses/replicate-prediction-z47nzizb3vicvkbb7lpwhdhugu.webp',
    '/poses/replicate-prediction-ngpcg6zbcfb3fsus6barnrxnii.webp',
    '/poses/replicate-prediction-nl4wvjbbfz5p6odh5fyjdquclu.webp',

    '/poses/replicate-prediction-nmv7vzbb55grmakuub7zgcz2oe.webp',
    '/poses/replicate-prediction-sqlwqtbbc5wa6pdtzkyvksddoq.webp',
    '/poses/replicate-prediction-t36vwbjbhcsnrhq7pfd57fj4oi.webp',
    '/poses/replicate-prediction-uyjfl3rbuuqnn3uj34nnu4dzou.webp',

    '/poses/replicate-prediction-p5za6yjbgtmz5mpvlu37lw3arq.webp',
    '/poses/replicate-prediction-uxato4bbkuzwtqnbdifeczx5bi.webp',
    '/poses/replicate-prediction-wp5jjsrbrjrfwlbja573bpptee.webp',
    '/poses/replicate-prediction-isxwlijbwobuhevzylju2qgzay.webp',

  ];
  const isMobile = useIsMobile();


  return (
    <div className={`imageGrid ${isMobile ? 'mobile' : 'desktop'}`}>
      {imageSources.map((src, index) => (
        <div
          key={index}
          className={`imageItemWrapper ${poseURL === src ? 'selected' : ''}`}
          onClick={() => onImageSelect(src)}
        >
          <img
            src={src}
            alt={`Pose Image ${index}`}
            className="imageItem"
          />
          {poseURL === src && <div className="selectedLabel">Selected Attire</div>}
        </div>
      ))}
    </div>
  );
}
function UploadButtonAndPreview() {
  return (
    <div className="flex items-center">
      <div className="w-32 h-32 p-2 border-2 border-blue-600 border-dashed rounded-lg mr-4">
        <img src="https://placehold.co/128x128" alt="Placeholder for user uploaded face" className="rounded-lg" />
      </div>
      <button className="bg-blue-600 text-white py-2 px-4 rounded">Upload complete</button>
    </div>
  );
}

export default GeneratePhotosPage;
