import React, { createContext, useContext, useState } from 'react';

const ImageGenerationContext = createContext();

export const useImageGeneration = () => useContext(ImageGenerationContext);

export const ImageGenerationProvider = ({ children }) => {
  const [shouldSync, setShouldSync] = useState(false);

  const startSyncing = () => {
    console.log("Starting syncing...");
    setShouldSync(true);
  };
  
  const stopSyncing = () => {
    console.log("Stopping syncing...");
    setShouldSync(false);
  };
  
  return (
    <ImageGenerationContext.Provider value={{ shouldSync, startSyncing, stopSyncing }}>
      {children}
    </ImageGenerationContext.Provider>
  );
};

export default ImageGenerationProvider;