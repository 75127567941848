import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { doc, onSnapshot, updateDoc, arrayUnion } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useImageGeneration } from './ImageGenerationContext'; // Import the hook

import './PopulateGeneratedImages.css';

function PopulateGeneratedImages() {
  const [generatedImages, setGeneratedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasHovered, setHasHovered] = useState(false);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const { shouldSync, stopSyncing } = useImageGeneration(); // Destructuring for context values
  // Initialize initialuploadsInfolength state
  const [initialuploadsInfolength, setInitialuploadsInfolength] = useState(0);

  useEffect(() => {
    let timerId; // Add a timer ID variable
    let intervalId;
    const auth = getAuth();
    let unsubscribeFromAuth = null;
    let unsubscribeFromFirestore = null;

    const fetchImages = (user) => {
      unsubscribeFromFirestore = onSnapshot(doc(db, "users", user.uid), (doc) => {
        console.log('Fetching images for user:', user.uid, '  time:', new Date());

        if (doc.exists()) {
          const userData = doc.data();
          setGeneratedImages(userData.uploadsInfo || []);
          setIsLoading(false);
          if (userData.uploadsInfo && userData.uploadsInfo.length > initialuploadsInfolength && shouldSync) {
            // Stop syncing if new images are detected
            stopSyncing();
            clearInterval(intervalId);
            clearTimeout(timerId);

          }
        } else {
          console.log("No such document!");
          setIsLoading(false);
        }
      }, (error) => {
        console.error("Error fetching generated images: ", error);
        setIsLoading(false);
      });
    };

    unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchImages(user);
      } else {
        setGeneratedImages([]);
        setIsLoading(false);
      }
    });

    if (shouldSync) {
      // Set a timer to wait for 30 seconds before syncing for the first time
      timerId = setTimeout(() => {
        // Start interval after waiting for 30 seconds
        intervalId = setInterval(() => {
          const user = auth.currentUser;
          if (user) {
            fetchImages(user);
          }
        }, 60000); // Sync every 60 seconds
      }, 40000); // Wait for 40 seconds before syncing for the first time
  
      // Set a timer to stop syncing after 6 minutes (6 * 60 * 1000 milliseconds)
      setTimeout(() => {
        stopSyncing();
        clearInterval(intervalId);
      }, 11 * 60 * 1000);
    }
        // Store the initialuploadsInfolength when the component mounts
        setInitialuploadsInfolength(generatedImages.length);
        console.log("initial upload length:",initialuploadsInfolength );
  
      // Effect to handle tab visibility
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const user = getAuth().currentUser;
        if (user) {
          fetchImages(user);
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    
    return () => {
      if (unsubscribeFromAuth) unsubscribeFromAuth();
      if (unsubscribeFromFirestore) unsubscribeFromFirestore();
      if (intervalId) clearInterval(intervalId); // Clear interval on cleanup
      if (timerId) clearTimeout(timerId); // Clear timer on cleanup
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [shouldSync, stopSyncing]); // Rerun effect if shouldSync changes

  
  const logUserActivity = (activity) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);

      updateDoc(userDocRef, {
        activities: arrayUnion(activity)
      }).catch(console.error);
    }
  };

  const handleHover = (imageId, imageUrl) => {
    if (!hasHovered) {
      logUserActivity({
        type: "hovered",
        timestamp: Date.now(),
        imageId: imageId,
        imageUrl: imageUrl
      });
      setHasHovered(true);
    }
  };

  const handleDownload = (url, index) => {
    if (!hasDownloaded) {
      logUserActivity({
        type: "downloaded",
        timestamp: Date.now(),
        imageId: generatedImages[index].id,
        imageUrl: url
      });
      setHasDownloaded(true);
    }

    const storage = getStorage();
    getDownloadURL(ref(storage, extractImagePath(url)))
      .then(downloadUrl => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
          const blob = xhr.response;
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = `GeneratedImage_${index}.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(blobUrl);
        };
        xhr.open('GET', downloadUrl);
        xhr.send();
      })
      .catch(error => {
        console.error("Error downloading the image: ", error);
      });
  };

  const extractImagePath = (url) => {
    const urlParts = new URL(url);
    const path = urlParts.pathname.split('/o/')[1];
    return decodeURIComponent(path);
  };

  return (
    <div className='populate-generated-images'>
      <hr/>

      <h1>Your Transformed Look In Desired Outfit</h1>

      <div className="imageFlex">
        {isLoading ? (
          <p>Loading images...</p>
        ) : generatedImages.length > 0 ? (
          generatedImages.map((image, index) => (
            <div key={image.id} className="imageItem"
                 onMouseEnter={() => handleHover(image.id, image.url)}>
              <img src={image.url} alt={`Generated Image ${index}`} />
              <button
                onClick={() => handleDownload(image.url, index)}
                className="downloadIcon"
                title="Download Image"
                aria-label="Download Image"
              >
                &#x2B07; {/* Unicode down arrow symbol */}
              </button>
            </div>
          ))
        ) : (
          <p>Waiting for your awesome image to be generated </p>
        )}
      </div>
    </div>
  );
}

export default PopulateGeneratedImages;
