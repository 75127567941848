// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Home from './Home'; // Create a separate Home component
import GeneratePhotosPage from './GeneratePhotosPage';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
    return (
        <Router>
            <div className="bg-gray-100">
                <Routes>
                    <Route path="/" element={<GeneratePhotosPage />} />
                    <Route path="/generate-photos" element={<GeneratePhotosPage />} />
                    <Route path="/privacy-policy" element= {<PrivacyPolicy />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
