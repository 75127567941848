//GeneratePhotosPageUpload.js
import React, { useEffect, useState,useRef } from "react";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "./firebase"; // Adjust this import according to your file structure
import './GeneratePhotosPageUpload.css'; // Import the CSS file
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import { signInWithGoogle } from './GoogleLogin'; // Adjust the import path as needed

function GeneratePhotosPageUpload({ onUploadComplete ,onUploadStatusChange}) {
  
  const [userId, setUserId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false); // New state for upload status
  const [showLoginModal, setShowLoginModal] = useState(false); // State to control login modal visibility
  const fileInputRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
        setShowLoginModal(false);
        console.log("userid", user.uid);
      } else {
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const compressImage = async (imageFile) => {
    let compressedFile = imageFile;
    const options = {
      maxSizeMB: 0.7,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
  
    try {
      compressedFile = await imageCompression(imageFile, options);
      // If the file is still above 1MB after compression, try again with lower quality
      if (compressedFile.size > 1024 * 1024) {
        const moreAggressiveOptions = {
          ...options,
          maxSizeMB: 0.4, // Set a lower target size to force more compression
          maxWidthOrHeight: 1280, // Reduce dimensions further if needed
        };
        compressedFile = await imageCompression(compressedFile, moreAggressiveOptions);
      }
      return compressedFile;
    } catch (error) {
      console.error("Error compressing the image:", error);
      throw error; // rethrow the error to be handled by the caller
    }
  };
  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      return;
    }

    if (!userId) {
      console.log("userid absent");
      setShowLoginModal(true); // Show login modal instead of alert
      alert("Please Log In to upload a face image.");
      return;
    }
    
    
    // Start the upload process
    setIsUploading(true);
    onUploadStatusChange(true); // Notify parent component about the upload status

    let fileToUpload = file;
    // Compress the file if it's larger than 1MB
    if (file.size > 720 * 1024) {
      try {
        fileToUpload = await compressImage(file);
        console.log("fileToUpload: ", fileToUpload.size)
      } catch (error) {
        alert("Error compressing image: " + error.message);
        return;
      }
    }

    // Check if the file has a .heic extension
    const isHeicFile = fileToUpload.name.toLowerCase().endsWith('.heic');

    if (isHeicFile) {
      try {
        const jpegBlob = await heic2any({
          blob: fileToUpload,
          toType: 'image/jpeg',
        });

        // Set the converted JPEG blob as the source for image preview
        setUploadedImage(URL.createObjectURL(jpegBlob));


        const storage = getStorage();
        const storageRef = ref(storage, `uploads/${userId}/${fileToUpload.name}`);
        await uploadBytes(storageRef, jpegBlob);

        const downloadURL = await getDownloadURL(storageRef);
        setUploadedImage(downloadURL);
        onUploadComplete(downloadURL);

        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const currentUploads = userDocSnapshot.data().uploads || 0;

        await updateDoc(userDocRef, {
          uploads: currentUploads + 1,
        });
      } catch (error) {
        console.error("Error converting HEIC to JPEG:", error);
        // Handle any errors here
      } finally {
        // End the upload process
        setIsUploading(false);
        onUploadStatusChange(false); // Notify parent component about the upload status

      }
    } else {
      // If not a HEIC file, proceed with the existing logic
      const reader = new FileReader();
      reader.onload = (e) => {
        setUploadedImage(e.target.result);
      };
      reader.readAsDataURL(fileToUpload);

      setIsUploading(true);
      onUploadStatusChange(true); // Notify parent component about the upload status

      try {
        const storage = getStorage();
        const storageRef = ref(storage, `uploads/${userId}/${fileToUpload.name}`);
        await uploadBytes(storageRef, fileToUpload);

        const downloadURL = await getDownloadURL(storageRef);
        setUploadedImage(downloadURL);
        onUploadComplete(downloadURL);

        const userDocRef = doc(db, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);
        const currentUploads = userDocSnapshot.data().uploads || 0;

        await updateDoc(userDocRef, {
          uploads: currentUploads + 1,
        });
      } catch (error) {
        console.error("Error uploading file: ", error);
        // Handle any errors here
      } finally {
        setIsUploading(false);
        onUploadStatusChange(false);
      }
    }
  };

  const handleFileSelectAttempt = (event) => {
    event.preventDefault(); // Prevent the file input dialog from opening

    if (!userId) {
      setShowLoginModal(true); // Show login modal if not logged in
    } else {
      fileInputRef.current.click(); // Programmatically open the file dialog if logged in
    }
  };

  return (
    <div className="bg-gray-100 p-6 mt-6 border-b-2 border-gray-200 text-center">
      <h2 className="text-xl font-semibold mb-4">2. Upload Your Photo</h2>
      <div className="imageUploadContainer">
        <label htmlFor="file-upload" className="imageItem" onClick={handleFileSelectAttempt}>
          <img
            className="uploadImage"
            src={uploadedImage || (showLoginModal ? "./faceuploadplaceholderdark.webp" : "./faceuploadplaceholder.webp")}
            alt="Uploaded face or generic illustration"
          />
            {showLoginModal && (  <div className="loginOverlay"> 
                <p className="loginOverlay-p">Please Login/Signup Below To Upload Your Face Photo</p>
               <button onClick={signInWithGoogle} className="py-2 px-4 rounded text-white bg-blue-500">Log In</button>
               </div>  
)}

          <span className="uploadText">{isUploading ? 'Uploading...' : (userId ? 'Click to upload' : '')}</span>
        </label>
        <input
          type="file"
          id="file-upload"
          accept="image/*"
          onChange={handleFileUpload}
          className="fileInput hidden"
          ref={fileInputRef}
        />
      </div>
      {/* <span className="uploadText">{isUploading ? 'Uploading...' : (userId ? 'Click to upload' : 'Please Login First')}</span> */}

    </div>
  );
}


export default GeneratePhotosPageUpload;
