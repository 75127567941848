import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth, db } from './firebase'; // Adjust the path accordingly
import { doc, getDoc, setDoc } from "firebase/firestore";

const createUserDocument = async (user, utmParams) => {
  const userRef = doc(db, "users", user.uid);
  await setDoc(userRef, {
    email: user.email,
    uid: user.uid,
    uploads: 0,
    premium_request: false,
    // Store the UTM params or an empty object if none
    utm_params: utmParams || {}
  });
};

const extractUtmParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  return {
    utm_source: queryParams.get('utm_source'),
    utm_medium: queryParams.get('utm_medium'),
    utm_campaign: queryParams.get('utm_campaign'),
    utm_term: queryParams.get('utm_term'),
    utm_content: queryParams.get('utm_content'),
  };
};

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);

    const userDocRef = doc(db, "users", result.user.uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (!userDocSnapshot.exists()) {
      const utmParams = extractUtmParams(); // Extract UTM parameters
      await createUserDocument(result.user, utmParams); // Pass UTM parameters when creating the user document
    }
  } catch (error) {
    console.error("Error during Google sign in: ", error.message);
  }
};

const signOut = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error("Error during sign out: ", error.message);
  }
};

const handlePremiumRequest = async () => {
  const user = auth.currentUser;
  
  if (!user) {
    alert("Please Log In to request premium services.");
    return;
  }

  try {
    const userDocRef = doc(db, "users", user.uid);
    await setDoc(userDocRef, {
      premium_request: true
    }, { merge: true });

    alert("Your request for premium services has been submitted. You will be contacted by our sales team soon.");
  } catch (error) {
    console.error("Error submitting premium request: ", error);
  }
};

export { signInWithGoogle, signOut, handlePremiumRequest };
