"use strict";
import {initializeApp} from 'firebase/app';
import {getAuth, GoogleAuthProvider} from 'firebase/auth';
// import { getStorage } from 'firebase/storage'; // Import storage from Firebase
import {getStorage} from 'firebase/storage';
import {getFirestore} from 'firebase/firestore'; // Add this import
import {getFunctions} from 'firebase/functions'; // Import the functions service
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvCloyMwAdZzcVpHiI0De7D5hv9pH4AnM",
  authDomain: "quick-click-studio.firebaseapp.com",
  projectId: "quick-click-studio",
  storageBucket: "quick-click-studio.appspot.com",
  messagingSenderId: "372965253546",
  appId: "1:372965253546:web:381028d63f6b25af26cbd3",
  measurementId: "G-W0N2K3F4HJ"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const storage = getStorage(app); // Initialize storage
const db = getFirestore(app); // Initialize Firestore
const functions = getFunctions(app); // Initialize the functions service
const analytics = getAnalytics(app);

export { auth, googleProvider, storage, db,functions,analytics }; // Export storage along with auth, googleProvider, and db
