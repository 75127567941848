import React, { useEffect, useState } from 'react'; // Import useState here
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth functions


const RazorpayScriptUrl = 'https://checkout.razorpay.com/v1/checkout.js';
// Replace with the URL of your deployed Firebase function
const API_BASE_URL = 'https://us-central1-quick-click-studio.cloudfunctions.net/api'; 

function RazorpayPayment() {
    const [userId, setUserId] = useState(null); // State to store the user ID
    const [isProcessing, setIsProcessing] = useState(false); // New state variable for tracking processing status

    // Function to load the Razorpay SDK script
    const loadRazorpayScript = (src) => {
        return new Promise((resolve, reject) => {
            try {
                const script = document.createElement('script');
                script.src = src;
                script.onload = () => resolve(true);
                script.onerror = () => reject(false);
                document.body.appendChild(script);
            } catch (error) {
                console.error('Error loading Razorpay SDK:', error);
                reject(error);
            }
        });
    };

    // Preload the Razorpay SDK when the component mounts
    // eslint-disable-next-line no-undef
    // Subscribe to user authentication state changes
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUserId(user ? user.uid : null);
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

        const showRazorpay = async () => {
            // Ensure user is logged in before proceeding

            if (!userId) {
                alert('Please log in to make a payment.');
                return;
            }
            setIsProcessing(true); // Start processing
   
            try {

                const res = await loadRazorpayScript(RazorpayScriptUrl);
                if (!res) {
                    console.error('Failed to load Razorpay SDK');
                    alert('Failed to load Razorpay SDK. Please check your internet connection.');
                    setIsProcessing(false); // Stop processing if there's an error
                    return;
                }

                const { data } = await axios.post(`${API_BASE_URL}/create-order`, {
                    // Send necessary data for order creation
                    amount: 100, // Example amount, replace with the actual amount
                    userId: userId, // Include the userId in the request body
                    // Add other order details as needed
                });
                const { amount, id: order_id, currency } = data;

                console.log('Order details:', data);

                const options = {
                    key: 'rzp_live_8NL6JRLYQFJW2X', // Replace with your Razorpay API key
                    amount: amount.toString(),
                    currency: currency,
                    name: 'Elite Corporate Shots',
                    description: 'Starter Plan Payment',
                    order_id: order_id,
                    handler: async function (response) {
                        try {
                            console.log('Payment response:', response);
                            setIsProcessing(false); // Stop processing once the payment gateway is displayed

                            const verification = await axios.post(`${API_BASE_URL}/verify-payment`, {
                                ...response,
                                order_id,
                                userId, // Include userId in the request body
                            });
                            console.log('Payment verification response:', verification.data);

                            if (verification.data.success) {
                                console.log('Payment successful');
                                alert('Payment successful');
                            } else {
                                console.error('Payment verification failed');
                                alert('Payment verification error. If money has been deducted, please fill out the Feedback form. We will increase your image limit. Apologies for the inconvenience.');
                            }
                        } catch (error) {
                            console.error('Error in payment verification:', error);
                            alert('Payment verification error. If money has been deducted, please fill out the Feedback form. We will increase your image limit. Apologies for the inconvenience.');
                        }
                    },
                    prefill: {
                        name: 'Elite Corporate Shots',
                        email: 'barsopia.vivek@gmail.com',
                        contact: '+91 9969815563'
                    },
                    theme: {
                        color: '#61dafb'
                    }
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            } catch (error) {
                console.error('Error in creating Razorpay order:', error);
                alert('Error in creating Razorpay order. Please try again.');
                setIsProcessing(false); // Stop processing in case of an error
            }
            setIsProcessing(false); // Stop processing once the payment gateway is displayed
        };

        return (
            <button onClick={showRazorpay} disabled={isProcessing}>
                {isProcessing ? 'Processing(wait 10 seconds)...' : 'Pay with RazorPay or PayPal'}
            </button>          
        );
    }

export default RazorpayPayment;
