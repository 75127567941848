import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import RazorpayPayment from './razorpay';
import "./GeneratePhotosPageAPIcall.css";
import PropTypes from 'prop-types'; // Import PropTypes
import { useImageGeneration } from './ImageGenerationContext';

function GeneratePhotosPageAPIcall({ downloadURL, poseURL, isUploading }) {
  const [output, setOutput] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('Generate Your Image');
  const [showRazorpayButton, setShowRazorpayButton] = useState(false);
  const { startSyncing } = useImageGeneration();

  const createTimeout = (duration) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out'));
      }, duration);
    });
  };

  const handleGenerateImage = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert("Please Log In to generate an image.");
      return;
    }

    if (!downloadURL) {
      if (isUploading) {
        alert("Your photo is still uploading. Please wait.");
      } else {
        alert("Please upload your photo.");
      }
      return;
    }

    if (!poseURL) {
      alert("Please select clothing style.");
      return;
    }

    startSyncing();
    setIsButtonDisabled(true);
    setButtonText("Generating(wait 60 seconds)");

    try {
      const functions = getFunctions();
      const replicateAPIFunction = httpsCallable(functions, 'runReplicateAPI');

      const result = await Promise.race([
        replicateAPIFunction({
          prompt: "null",
          isDryRun: false,
          faceImageURL: downloadURL,
          poseImageURL: poseURL,
        }),
        createTimeout(1800000)
      ]);

      if (result.data.success) {
        setOutput({ replicateOutput: result.data.replicateOutput });
        setStatusMessage("Image generated successfully.");
      } else {
        setStatusMessage(result.data.message);
        if (result.data.message.includes("reached the limit")) {
          setShowRazorpayButton(true);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error && error.message.includes('deadline-exceeded')) {
        setStatusMessage("Task submitted successfully but it might take upto 3 more minutes. Apologies for the wait time.");
      } else {
        setStatusMessage("Error occurred while generating the image. Please try again.");
      }
    } finally {
      setIsButtonDisabled(false);
      setButtonText("Generate Your Image");
    }
  };

  return (
    <div className="mt-6">
      <button
        onClick={handleGenerateImage}
        disabled={isButtonDisabled}
        className={`${isButtonDisabled ? 'button-disabled' : ''}`}
      >
        {buttonText}
      </button>
      {statusMessage && <p style={{ marginBlockEnd: '0' }}>{statusMessage}</p>}
      {showRazorpayButton && <RazorpayPayment />}
      {output?.replicateOutput && (
        <p>
          <img src={output.replicateOutput[0]} alt="Generated Image by Replicate API" />
        </p>
      )}
    </div>
  );
}

GeneratePhotosPageAPIcall.propTypes = {
  downloadURL: PropTypes.string.isRequired,
  poseURL: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired
};

export default GeneratePhotosPageAPIcall;
